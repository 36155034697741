"use client"
import dynamic from "next/dynamic"
import React, { type FC, memo, useEffect, useRef, useState } from "react"

import type { LottieProps } from "./Lottie.model"

import styles from "./Lottie.module.scss"
import { ANIMATIONS } from "./LottieAnimations"

import { create } from "@/helpers/bem"

const DotLottieReact = dynamic(
  () =>
    import("@lottiefiles/dotlottie-react").then(
      (module) => module.DotLottieReact,
    ),
  { ssr: false },
)

const bem = create(styles, "Lottie")

export const Lottie: FC<LottieProps> = memo(
  ({ className, animationName, containerClassName, ...props }) => {
    if (!ANIMATIONS?.[animationName]) return null

    const [isInView, setIsInView] = useState(false)
    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry?.isIntersecting) {
          setIsInView(true)
          observer.disconnect()
        }
      })

      const container = containerRef.current
      if (container) {
        observer.observe(container)
      }

      return () => {
        if (container) observer.disconnect()
      }
    }, [])

    if (!isInView)
      return (
        <div
          ref={containerRef}
          className={bem("placeholder", undefined, containerClassName)}
        ></div>
      )
    return (
      <div
        ref={containerRef}
        className={bem("container", undefined, containerClassName)}
      >
        <DotLottieReact
          autoplay
          autoResizeCanvas
          loop
          className={bem(undefined, undefined, className)}
          src={ANIMATIONS?.[animationName]} // Ensure you're using .lottie format if possible
          useFrameInterpolation={false}
          renderConfig={{
            devicePixelRatio: 1, // Lower the pixel ratio for smaller file size
          }}
          {...props}
        />
      </div>
    )
  },
)

Lottie.displayName = "Lottie"
