export const ANIMATIONS = {
  SolutionUIUXDesign:
    "https://lottie.host/af0b17d4-cc93-48bb-b388-d058f579ae3a/mzzgcsHwL4.lottie",
  SolutionSoftwareDevelopment:
    "https://lottie.host/bf06b5a0-be40-4f2a-95e4-ae3e033006d3/pxQ7Wtj79u.lottie",
  SolutionAppDevelopment:
    "https://lottie.host/24da243e-b6e4-4180-abd2-fc70ece1a39c/UDS9WBtmnP.lottie",
  SolutionMarketing:
    "https://lottie.host/adcecb1c-461d-44a1-88bb-33ac05d46d3c/3MJbj0EmAN.lottie",
  SolutionCloud:
    "https://lottie.host/1f4750fd-b6d1-467c-956e-aa73a135859e/eFYRZy1wGx.lottie",
  SolutionCMS:
    "https://lottie.host/ba018362-745a-437f-9d7b-d73b9e703330/rzxWewbkvC.lottie",
  SolutionProjectManagement:
    "https://lottie.host/688bcc01-8a1d-409b-908e-a56173c7927b/svTnec8A7e.lottie",
  LogoAWS:
    "https://lottie.host/0db4ad01-4be2-439f-858e-5b35a6a3fa10/na8bHDn7XH.lottie",
  LogoGCP:
    "https://lottie.host/7485553e-5baa-4463-a4cd-dc34df3e1966/b8mdLcofyU.lottie",
  LogoReact:
    "https://lottie.host/1f3571ff-9caf-4669-9cca-eb49f4481eab/rP3fY4zubb.lottie",
  LogoNodejs:
    "https://lottie.host/9bab6357-0884-43b8-ae4d-46e9ed1f175f/B1hOYuLcCu.lottie",
  LogoGithub:
    "https://lottie.host/e83a6197-0828-4cfe-b4e3-c02c64ae62e9/6Dcb0MXTTu.lottie",
  DataAnalytics:
    "https://lottie.host/39705791-7f99-48e4-8bd5-bc591c8c5356/TUI5KNWHZf.lottie",
  CustomerFeedback:
    "https://lottie.host/a747a4f7-edf5-4d39-a815-513ec58b0f08/l0JXyd15wv.lottie",
  NotFound:
    "https://lottie.host/e8fe35e8-e723-464f-9a33-aa57905af549/qzFAkPgSK0.lottie",
} as const
